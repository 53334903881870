var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"contextmenu-submenu-fade"}},[(_vm.visible)?_c('div',{ref:"menu",class:[_vm.CLASS_MENU, 'menu', _vm.customClass],style:({ left: _vm.style.left + 'px', top: _vm.style.top + 'px', minWidth: _vm.style.minWidth + 'px', zIndex: _vm.style.zIndex }),on:{"contextmenu":function (e) { return e.preventDefault(); }}},[(_vm.showOverlay)?_c('div',{staticClass:"menu-overlay",style:(_vm.overlayPosition),on:{"click":_vm.close}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"menu_body"},[_vm._l((_vm.items),function(item,index){return [(!item.hidden)?[(item.disabled)?_c('div',{key:index + '_disabled',class:[
            _vm.CLASS_MENU_ITEM, _vm.CLASS_MENU_ITEM_UNCLICKABLE,
            'menu_item', 'menu_item__disabled',
            item.divided ? 'menu_item__divided' : null,
            item.customClass || null
          ]},[(_vm.hasIcon)?_c('div',{staticClass:"menu_item_icon"},[(item.icon)?_c('i',{class:item.icon}):_vm._e()]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"menu_item_label"},[_vm._v(_vm._s(item.label))]),_vm._v(" "),_c('div',{staticClass:"menu_item_expand_icon"})]):(item.children)?_c('div',{key:index + '_children',class:[
            _vm.CLASS_MENU_ITEM, _vm.CLASS_MENU_ITEM_UNCLICKABLE,
            'menu_item', 'menu_item__available',
            _vm.activeSubmenu.index === index ? 'menu_item_expand' : null,
            item.divided ? 'menu_item__divided' : null,
            item.customClass || null
          ],on:{"mouseenter":function ($event) { return _vm.enterItem($event, item, index); }}},[(_vm.hasIcon)?_c('div',{staticClass:"menu_item_icon"},[(item.icon)?_c('i',{class:item.icon}):_vm._e()]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"menu_item_label"},[_vm._v(_vm._s(item.label))]),_vm._v(" "),_c('div',{staticClass:"menu_item_expand_icon"},[_vm._v("▶")])]):_c('div',{key:index,class:[
            _vm.CLASS_MENU_ITEM, _vm.CLASS_MENU_ITEM_CLICKABLE,
            'menu_item', 'menu_item__available',
            item.divided ? 'menu_item__divided' : null,
            item.customClass || null
          ],on:{"mouseenter":function ($event) { return _vm.enterItem($event, item, index); },"click":function($event){return _vm.itemClick(item)}}},[(_vm.hasIcon)?_c('div',{staticClass:"menu_item_icon"},[(item.icon)?_c('i',{class:item.icon}):_vm._e()]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"menu_item_label"},[_vm._v(_vm._s(item.label))]),_vm._v(" "),_c('div',{staticClass:"menu_item_expand_icon"})])]:_vm._e()]})],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }