
// noinspection JSUnusedGlobalSymbols

import {install, Vue} from './install'
import {
  warn,
  error,
  isNull,
  parseArgs,
  isPlainObject,
  isObject,
  isArray,
  isBoolean,
  isString,
  isFunction,
  looseClone,
  remove,
  arrayFrom,
  includes,
  merge,
  numberFormatKeys,
  dateTimeFormatKeys,
  escapeParams
} from './util'
import BaseFormatter from './format'
import I18nPath from './path'



const htmlTagMatcher = /<\/?[\w\s="/.':;#-\/]+>/
const linkKeyMatcher = /(?:@(?:\.[a-zA-Z]+)?:(?:[\w\-_|./]+|\([\w\-_:|./]+\)))/g
const linkKeyPrefixMatcher = /^@(?:\.([a-zA-Z]+))?:/
const bracketsMatcher = /[()]/g
const defaultModifiers = {
  'upper': str => str.toLocaleUpperCase(),
  'lower': str => str.toLocaleLowerCase(),
  'capitalize': str => `${str.charAt(0).toLocaleUpperCase()}${str.substr(1)}`
}

const defaultFormatter = new BaseFormatter()

export default class VueI18n {
  static install
  static version
  static availabilities

  _vm
  _formatter
  _modifiers
  _root
  _sync
  _fallbackRoot
  _fallbackRootWithEmptyString
  _localeChainCache
  _missing
  _exist
  _silentTranslationWarn
  _silentFallbackWarn
  _formatFallbackMessages
  _dateTimeFormatters
  _numberFormatters
  _path
  _dataListeners
  _componentInstanceCreatedListener
  _preserveDirectiveContent
  _warnHtmlInMessage
  _escapeParameterHtml
  _postTranslation
  __VUE_I18N_BRIDGE__
  pluralizationRules

  getChoiceIndex

  constructor(options = {}) {
    // Auto install if it is not done yet and `window` has `Vue`.
    // To allow users to avoid auto-installation in some cases,
    // this code should be placed here. See #290
    /* istanbul ignore if */
    if (!Vue && typeof window !== 'undefined' && window.Vue) {
      install(window.Vue)
    }

    const locale = options.locale || 'en-US'
    const fallbackLocale = options.fallbackLocale === false
      ? false
      : options.fallbackLocale || 'en-US'
    const messages = options.messages || {}
    const dateTimeFormats = options.dateTimeFormats || options.datetimeFormats || {}
    const numberFormats = options.numberFormats || {}

    this._vm = null
    this._formatter = options.formatter || defaultFormatter
    this._modifiers = options.modifiers || {}
    this._missing = options.missing || null
    this._root = options.root || null
    this._sync = options.sync === undefined ? true : !!options.sync
    this._fallbackRoot = options.fallbackRoot === undefined
      ? true
      : !!options.fallbackRoot
    this._fallbackRootWithEmptyString = options.fallbackRootWithEmptyString === undefined
      ? true
      : !!options.fallbackRootWithEmptyString
    this._formatFallbackMessages = options.formatFallbackMessages === undefined
      ? false
      : !!options.formatFallbackMessages
    this._silentTranslationWarn = options.silentTranslationWarn === undefined
      ? false
      : options.silentTranslationWarn
    this._silentFallbackWarn = options.silentFallbackWarn === undefined
      ? false
      : !!options.silentFallbackWarn
    this._dateTimeFormatters = {}
    this._numberFormatters = {}
    this._path = new I18nPath()
    this._dataListeners = new Set()
    this._componentInstanceCreatedListener = options.componentInstanceCreatedListener || null
    this._preserveDirectiveContent = options.preserveDirectiveContent === undefined
      ? false
      : !!options.preserveDirectiveContent
    this.pluralizationRules = options.pluralizationRules || {}
    this._warnHtmlInMessage = options.warnHtmlInMessage || 'off'
    this._postTranslation = options.postTranslation || null
    this._escapeParameterHtml = options.escapeParameterHtml || false

    if ('__VUE_I18N_BRIDGE__' in options) {
      this.__VUE_I18N_BRIDGE__ = options.__VUE_I18N_BRIDGE__
    }

    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index
     */
    this.getChoiceIndex = (choice, choicesLength) => {
      const thisPrototype = Object.getPrototypeOf(this)
      if (thisPrototype && thisPrototype.getChoiceIndex) {
        const prototypeGetChoiceIndex = (thisPrototype.getChoiceIndex)
        return (prototypeGetChoiceIndex).call(this, choice, choicesLength)
      }

      // Default (old) getChoiceIndex implementation - english-compatible
      const defaultImpl = (_choice, _choicesLength) => {
        _choice = Math.abs(_choice)

        if (_choicesLength === 2) {
          return _choice
            ? _choice > 1
              ? 1
              : 0
            : 1
        }

        return _choice ? Math.min(_choice, 2) : 0
      }

      if (this.locale in this.pluralizationRules) {
        return this.pluralizationRules[this.locale].apply(this, [choice, choicesLength])
      } else {
        return defaultImpl(choice, choicesLength)
      }
    }


    this._exist = (message, key) => {
      if (!message || !key) {
        return false
      }
      if (!isNull(this._path.getPathValue(message, key))) {
        return true
      }
      // fallback for flat key
      return !!message[key];

    }

    if (this._warnHtmlInMessage === 'warn' || this._warnHtmlInMessage === 'error') {
      Object.keys(messages).forEach(locale => {
        this._checkLocaleMessage(locale, this._warnHtmlInMessage, messages[locale])
      })
    }

    this._initVM({
      locale,
      fallbackLocale,
      messages,
      dateTimeFormats,
      numberFormats
    })
  }

  _checkLocaleMessage(locale, level, message) {
    const paths = []

    const fn = (level, locale, message, paths) => {
      if (isPlainObject(message)) {
        Object.keys(message).forEach(key => {
          const val = message[key]
          if (isPlainObject(val)) {
            paths.push(key)
            paths.push('.')
            fn(level, locale, val, paths)
            paths.pop()
            paths.pop()
          } else {
            paths.push(key)
            fn(level, locale, val, paths)
            paths.pop()
          }
        })
      } else if (isArray(message)) {
        message.forEach((item, index) => {
          if (isPlainObject(item)) {
            paths.push(`[${index}]`)
            paths.push('.')
            fn(level, locale, item, paths)
            paths.pop()
            paths.pop()
          } else {
            paths.push(`[${index}]`)
            fn(level, locale, item, paths)
            paths.pop()
          }
        })
      } else if (isString(message)) {
        const ret = htmlTagMatcher.test(message)
        if (ret) {
          const msg = `Detected HTML in message '${message}' of keypath '${paths.join('')}' at '${locale}'. Consider component interpolation with '<i18n>' to avoid XSS. See https://bit.ly/2ZqJzkp`
          if (level === 'warn') {
            warn(msg)
          } else if (level === 'error') {
            error(msg)
          }
        }
      }
    }

    fn(level, locale, message, paths)
  }

  _initVM(data) {
    const silent = Vue.config.silent
    Vue.config.silent = true
    this._vm = new Vue({data, __VUE18N__INSTANCE__: true})
    Vue.config.silent = silent
  }

  destroyVM() {
    this._vm.$destroy()
  }

  subscribeDataChanging(vm) {
    this._dataListeners.add(vm)
  }

  unsubscribeDataChanging(vm) {
    remove(this._dataListeners, vm)
  }

  watchI18nData() {

    return this._vm.$watch('$data', () => {
      const listeners = arrayFrom(this._dataListeners)
      let i = listeners.length
      while (i--) {
        Vue.nextTick(() => {
          listeners[i] && listeners[i].$forceUpdate()
        })
      }
    }, {deep: true})
  }

  watchLocale(composer) {
    if (!composer) {
      /* istanbul ignore if */
      if (!this._sync || !this._root) {
        return null
      }
      const target = this._vm
      return this._root.$i18n.vm.$watch('locale', (val) => {
        target.$set(target, 'locale', val)
        target.$forceUpdate()
      }, {immediate: true})
    } else {
      // deal with vue-i18n-bridge
      if (!this.__VUE_I18N_BRIDGE__) {
        return null
      }
      const self = this
      const target = this._vm
      return this.vm.$watch('locale', (val) => {
        target.$set(target, 'locale', val)
        if (self.__VUE_I18N_BRIDGE__ && composer) {
          composer.locale.value = val
        }
        target.$forceUpdate()
      }, {immediate: true})
    }
  }

  onComponentInstanceCreated(newI18n) {
    if (this._componentInstanceCreatedListener) {
      this._componentInstanceCreatedListener(newI18n, this)
    }
  }

  get vm() {
    return this._vm
  }

  get messages() {
    return looseClone(this._getMessages())
  }

  get dateTimeFormats() {return looseClone(this._getDateTimeFormats())}

  get numberFormats() {return looseClone(this._getNumberFormats())}

  get availableLocales() {
    return Object.keys(this.messages).sort()
  }

  get locale() {
    return this._vm.locale
  }

  set locale(locale) {
    this._vm.$set(this._vm, 'locale', locale)
  }

  get fallbackLocale() {
    return this._vm.fallbackLocale
  }

  set fallbackLocale(locale) {
    this._localeChainCache = {}
    this._vm.$set(this._vm, 'fallbackLocale', locale)
  }

  get formatFallbackMessages() {
    return this._formatFallbackMessages
  }

  set formatFallbackMessages(fallback) {
    this._formatFallbackMessages = fallback
  }

  get missing() {return this._missing}

  set missing(handler) {
    this._missing = handler
  }

  get formatter() {return this._formatter}

  set formatter(formatter) {
    this._formatter = formatter
  }

  get silentTranslationWarn() {
    return this._silentTranslationWarn
  }

  set silentTranslationWarn(silent) {this._silentTranslationWarn = silent}

  get silentFallbackWarn() {
    return this._silentFallbackWarn
  }

  set silentFallbackWarn(silent) {this._silentFallbackWarn = silent}

  get preserveDirectiveContent() {
    return this._preserveDirectiveContent
  }

  set preserveDirectiveContent(preserve) {
    this._preserveDirectiveContent = preserve
  }

  get warnHtmlInMessage() {
    return this._warnHtmlInMessage
  }

  set warnHtmlInMessage(level) {
    const orgLevel = this._warnHtmlInMessage
    this._warnHtmlInMessage = level
    if (orgLevel !== level && (level === 'warn' || level === 'error')) {
      const messages = this._getMessages()
      Object.keys(messages).forEach(locale => {
        this._checkLocaleMessage(locale, this._warnHtmlInMessage, messages[locale])
      })
    }
  }

  get postTranslation() {
    return this._postTranslation
  }

  set postTranslation(handler) {
    this._postTranslation = handler
  }

  get sync() {return this._sync}

  set sync(val) {
    this._sync = val
  }

  _getMessages() {
    return this._vm.messages
  }

  _getDateTimeFormats() {
    return this._vm.dateTimeFormats
  }

  _getNumberFormats() {
    return this._vm.numberFormats
  }

  warnDefault(locale, key, result, vm, values, interpolateMode) {
    if (!isNull(result)) {
      return result
    }
    if (this._missing) {
      const missingRet = this._missing.apply(null, [locale, key, vm, values])
      if (isString(missingRet)) {
        return missingRet
      }
    } else {
      if (process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key)) {
        warn(
          `Cannot translate the value of keypath '${key}'. ` +
          'Use the value of keypath as default.'
        )
      }
    }

    if (this._formatFallbackMessages) {
      const parsedArgs = parseArgs(...values)
      return this._render(key, interpolateMode, parsedArgs.params, key)
    } else {
      return key
    }
  }

  _isFallbackRoot(val) {
    return (this._fallbackRootWithEmptyString ? !val : isNull(val)) && !isNull(this._root) && this._fallbackRoot
  }

  _isSilentFallbackWarn(key) {
    return this._silentFallbackWarn instanceof RegExp
      ? this._silentFallbackWarn.test(key)
      : this._silentFallbackWarn
  }

  _isSilentFallback(locale, key) {
    return this._isSilentFallbackWarn(key) && (this._isFallbackRoot() || locale !== this.fallbackLocale)
  }

  _isSilentTranslationWarn(key) {
    return this._silentTranslationWarn instanceof RegExp
      ? this._silentTranslationWarn.test(key)
      : this._silentTranslationWarn
  }

  _interpolate(locale, message, key, host, interpolateMode, values, visitedLinkStack) {
    if (!message) {
      return null
    }

    const pathRet = this._path.getPathValue(message, key)
    if (isArray(pathRet) || isPlainObject(pathRet)) {
      return pathRet
    }

    let ret
    if (isNull(pathRet)) {
      /* istanbul ignore else */
      if (isPlainObject(message)) {
        ret = message[key]
        if (!(isString(ret) || isFunction(ret))) {
          if (process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key) && !this._isSilentFallback(locale, key)) {
            warn(`Value of key '${key}' is not a string or function !`)
          }
          return null
        }
      } else {
        return null
      }
    } else {
      /* istanbul ignore else */
      if (isString(pathRet) || isFunction(pathRet)) {
        ret = pathRet
      } else {
        if (process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key) && !this._isSilentFallback(locale, key)) {
          warn(`Value of key '${key}' is not a string or function!`)
        }
        return null
      }
    }

    // Check for the existence of links within the translated string
    if (isString(ret) && (ret.indexOf('@:') >= 0 || ret.indexOf('@.') >= 0)) {
      ret = this._link(locale, message, ret, host, 'raw', values, visitedLinkStack)
    }

    return this._render(ret, interpolateMode, values, key)
  }

  link(locale, message, str, host, interpolateMode, values, visitedLinkStack) {
    let ret = str

    const matches = str.match(linkKeyMatcher)

    for (let idx in matches) {
      if (!matches.hasOwnProperty(idx)) {
        continue
      }

      const link = matches[idx]
      const linkKeyPrefixMatches = link.match(linkKeyPrefixMatcher)
      const [linkPrefix, formatterName] = linkKeyPrefixMatches
      const linkPlaceholder = link.replace(linkPrefix, '').replace(bracketsMatcher, '')

      if (includes(visitedLinkStack, linkPlaceholder)) {
        if (process.env.NODE_ENV !== 'production') {
          warn(
            `Circular reference found. "${link}" is already visited in the chain of ${visitedLinkStack.reverse().join(
              ' <- '
            )}`
          )
        }
        return ret
      }

      visitedLinkStack.push(linkPlaceholder)

      let translated = this._interpolate(
        locale,
        message,
        linkPlaceholder,
        host,
        interpolateMode === 'raw' ? 'string' : interpolateMode,
        interpolateMode === 'raw' ? undefined : values,
        visitedLinkStack
      )

      if (this._isFallbackRoot(translated)) {
        if (process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(linkPlaceholder)) {
          warn(`Fall back to translate the link placeholder '${linkPlaceholder}' with root locale.`)
        }

        if (!this._root) {
          throw Error('unexpected error')
        }

        const root = this._root.$i18n
        translated = root._translate(
          root._getMessages(),
          root.locale,
          root.fallbackLocale,
          linkPlaceholder,
          host,
          interpolateMode,
          values
        )
      }

      translated = this.warnDefault(
        locale,
        linkPlaceholder,
        translated,
        host,
        isArray(values) ? values : [values],
        interpolateMode
      )

      if (this._modifiers.hasOwnProperty(formatterName)) {
        translated = this._modifiers[formatterName](translated)
      } else if (defaultModifiers.hasOwnProperty(formatterName)) {
        translated = defaultModifiers[formatterName](translated)
      }

      visitedLinkStack.pop()

      ret = !translated ? ret : ret.replace(link, translated)
    }

    return ret
  }

  _createMessageContext(values, formatter, path, interpolateMode) {
    const _list = Array.isArray(values) ? values : []
    const _named = typeof values === 'object' && values !== null ? values : {}
    const list = (index) => _list[index]
    const named = (key) => _named[key]
    const messages = this._getMessages()
    const locale = this.locale

    return {
      list,
      named,
      values,
      formatter,
      path,
      messages,
      locale,
      linked: (linkedKey) => this._interpolate(locale, messages[locale] || {}, linkedKey, null, interpolateMode, undefined, [linkedKey])
    }
  }

  _render(message, interpolateMode, values, path) {
    if (typeof message === 'function') {
      return message(
        this._createMessageContext(values, this._formatter || defaultFormatter, path, interpolateMode)
      )
    }

    let ret = this._formatter.interpolate(message, values, path)

    if (!ret) {
      ret = defaultFormatter.interpolate(message, values, path)
    }

    return interpolateMode === 'string' && typeof ret !== 'string' ? ret.join('') : ret
  }

  _appendItemToChain(chain, item, blocks) {
    let follow = false

    if (!chain.includes(item)) {
      follow = true

      if (item) {
        follow = item[item.length - 1] !== '!'
        item = item.replace(/!/g, '')
        chain.push(item)

        if (blocks && blocks[item]) {
          follow = blocks[item]
        }
      }
    }

    return follow
  }

  _appendLocaleToChain(chain, locale, blocks) {
    let follow
    const tokens = locale.split('-')
    do {
      const item = tokens.join('-')
      follow = this._appendItemToChain(chain, item, blocks)
      tokens.splice(-1, 1)
    } while (tokens.length && follow === true)
    return follow
  }

  _appendBlockToChain(chain, block, blocks) {
    let follow = true
    for (let i = 0; (i < block.length) && (isBoolean(follow)); i++) {
      const locale = block[i]
      if (isString(locale)) {
        follow = this._appendLocaleToChain(chain, locale, blocks)
      }
    }
    return follow
  }

  _getLocaleChain(start, fallbackLocale) {
    if (start === '') {
      return []
    }

    if (!this._localeChainCache) {
      this._localeChainCache = {}
    }

    let chain = this._localeChainCache[start]
    if (!chain) {
      if (!fallbackLocale) {
        fallbackLocale = this.fallbackLocale
      }
      chain = []

      // first block defined by start
      let block = [start]

      // while any intervening block found
      while (isArray(block)) {
        block = this._appendBlockToChain(
          chain,
          block,
          fallbackLocale
        )
      }

      // last block defined by default
      let defaults
      if (isArray(fallbackLocale)) {
        defaults = fallbackLocale
      } else if (isObject(fallbackLocale)) {
        /* $FlowFixMe */
        if (fallbackLocale['default']) {
          defaults = fallbackLocale['default']
        } else {
          defaults = null
        }
      } else {
        defaults = fallbackLocale
      }

      // convert defaults to array
      if (isString(defaults)) {
        block = [defaults]
      } else {
        block = defaults
      }
      if (block) {
        this._appendBlockToChain(
          chain,
          block,
          null
        )
      }
      this._localeChainCache[start] = chain
    }
    return chain
  }

  _translate(messages, locale, fallback, key, host, interpolateMode, args) {
    const chain = this._getLocaleChain(locale, fallback)
    let res
    for (let i = 0; i < chain.length; i++) {
      const step = chain[i]
      res =
        this._interpolate(step, messages[step], key, host, interpolateMode, args, [key])
      if (!isNull(res)) {
        if (step !== locale && process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key) && !this._isSilentFallbackWarn(key)) {
          warn(("Fall back to translate the keypath '" + key + "' with '" + step + "' locale."))
        }
        return res
      }
    }
    return null
  }

  _t(key, _locale, messages, host, ...values) {
    if (!key) {
      return ''
    }

    const parsedArgs = parseArgs(...values)
    if (this._escapeParameterHtml) {
      parsedArgs.params = escapeParams(parsedArgs.params)
    }

    const locale = parsedArgs.locale || _locale

    let ret = this._translate(
      messages, locale, this.fallbackLocale, key,
      host, 'string', parsedArgs.params
    )
    if (this._isFallbackRoot(ret)) {
      if (process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key) && !this._isSilentFallbackWarn(key)) {
        warn(`Fall back to translate the keypath '${key}' with root locale.`)
      }
      /* istanbul ignore if */
      if (!this._root) {
        throw Error('unexpected error')
      }
      return this._root.$t(key, ...values)
    } else {
      ret = this.warnDefault(locale, key, ret, host, values, 'string')
      if (this._postTranslation && ret !== null && ret !== undefined) {
        ret = this._postTranslation(ret, key)
      }
      return ret
    }
  }

  t(key, ...values) {
    return this._t(key, this.locale, this._getMessages(), null, ...values)
  }

  _i(key, locale, messages, host, values) {
    const ret =
      this._translate(messages, locale, this.fallbackLocale, key, host, 'raw', values)
    if (this._isFallbackRoot(ret)) {
      if (process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key)) {
        warn(`Fall back to interpolate the keypath '${key}' with root locale.`)
      }
      if (!this._root) {
        throw Error('unexpected error')
      }
      return this._root.$i18n.i(key, locale, values)
    } else {
      return this.warnDefault(locale, key, ret, host, [values], 'raw')
    }
  }

  i(key, locale, values) {
    /* istanbul ignore if */
    if (!key) {
      return ''
    }

    if (!isString(locale)) {
      locale = this.locale
    }

    return this._i(key, locale, this._getMessages(), null, values)
  }

  _tc(
    key,
    _locale,
    messages,
    host,
    choice,
    ...values
  ) {
    if (!key) {
      return ''
    }
    if (choice === undefined) {
      choice = 1
    }

    const predefined = {'count': choice, 'n': choice}
    const parsedArgs = parseArgs(...values)
    parsedArgs.params = Object.assign(predefined, parsedArgs.params)
    values = parsedArgs.locale === null ? [parsedArgs.params] : [parsedArgs.locale, parsedArgs.params]
    return this.fetchChoice(this._t(key, _locale, messages, host, ...values), choice)
  }

  fetchChoice(message, choice) {
    /* istanbul ignore if */
    if (!message || !isString(message)) {
      return null
    }
    const choices = message.split('|')

    choice = this.getChoiceIndex(choice, choices.length)
    if (!choices[choice]) {
      return message
    }
    return choices[choice].trim()
  }

  tc(key, choice, ...values) {
    return this._tc(key, this.locale, this._getMessages(), null, choice, ...values)
  }

  _te(key, locale, messages, ...args) {
    const _locale = parseArgs(...args).locale || locale
    return this._exist(messages[_locale], key)
  }

  te(key, locale) {
    return this._te(key, this.locale, this._getMessages(), locale)
  }

  getLocaleMessage(locale) {
    return looseClone(this._vm.messages[locale] || {})
  }

  setLocaleMessage(locale, message) {
    if (this._warnHtmlInMessage === 'warn' || this._warnHtmlInMessage === 'error') {
      this._checkLocaleMessage(locale, this._warnHtmlInMessage, message)
    }
    this._vm.$set(this._vm.messages, locale, message)
  }

  mergeLocaleMessage(locale, message) {
    if (this._warnHtmlInMessage === 'warn' || this._warnHtmlInMessage === 'error') {
      this._checkLocaleMessage(locale, this._warnHtmlInMessage, message)
    }
    this._vm.$set(this._vm.messages, locale, merge(
      typeof this._vm.messages[locale] !== 'undefined' && Object.keys(this._vm.messages[locale]).length
        ? Object.assign({}, this._vm.messages[locale])
        : {},
      message
    ))
  }

  getDateTimeFormat(locale) {
    return looseClone(this._vm.dateTimeFormats[locale] || {})
  }

  setDateTimeFormat(locale, format) {
    this._vm.$set(this._vm.dateTimeFormats, locale, format)
    this._clearDateTimeFormat(locale, format)
  }

  mergeDateTimeFormat(locale, format) {
    this._vm.$set(this._vm.dateTimeFormats, locale, merge(this._vm.dateTimeFormats[locale] || {}, format))
    this._clearDateTimeFormat(locale, format)
  }

  _clearDateTimeFormat(locale, format) {
    // eslint-disable-next-line no-autofix/prefer-const
    for (let key in format) {
      const id = `${locale}__${key}`

      if (!this._dateTimeFormatters.hasOwnProperty(id)) {
        continue
      }

      delete this._dateTimeFormatters[id]
    }
  }

  _localizeDateTime(
    value,
    locale,
    fallback,
    dateTimeFormats,
    key,
    options
  ) {
    let _locale = locale
    let formats = dateTimeFormats[_locale]

    const chain = this._getLocaleChain(locale, fallback)
    for (let i = 0; i < chain.length; i++) {
      const current = _locale
      const step = chain[i]
      formats = dateTimeFormats[step]
      _locale = step
      // fallback locale
      if (isNull(formats) || isNull(formats[key])) {
        if (step !== locale && process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key) && !this._isSilentFallbackWarn(key)) {
          warn(`Fall back to '${step}' datetime formats from '${current}' datetime formats.`)
        }
      } else {
        break
      }
    }

    if (isNull(formats) || isNull(formats[key])) {
      return null
    } else {
      const format = formats[key]

      let formatter
      if (options) {
        formatter = new Intl.DateTimeFormat(_locale, Object.assign({}, format, options))
      } else {
        const id = `${_locale}__${key}`
        formatter = this._dateTimeFormatters[id]
        if (!formatter) {
          formatter = this._dateTimeFormatters[id] = new Intl.DateTimeFormat(_locale, format)
        }
      }

      return formatter.format(value)
    }
  }

  _d(value, locale, key, options) {
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'production' && !VueI18n.availabilities.dateTimeFormat) {
      warn('Cannot format a Date value due to not supported Intl.DateTimeFormat.')
      return ''
    }

    if (!key) {
      const dtf = !options ? new Intl.DateTimeFormat(locale) : new Intl.DateTimeFormat(locale, options)
      return dtf.format(value)
    }

    const ret =
      this._localizeDateTime(value, locale, this.fallbackLocale, this._getDateTimeFormats(), key, options)
    if (this._isFallbackRoot(ret)) {
      if (process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key) && !this._isSilentFallbackWarn(key)) {
        warn(`Fall back to datetime localization of root: key '${key}'.`)
      }
      /* istanbul ignore if */
      if (!this._root) {
        throw Error('unexpected error')
      }
      return this._root.$i18n.d(value, key, locale)
    } else {
      return ret || ''
    }
  }

  d(value, ...args) {
    let locale = this.locale
    let key = null
    let options = null


    if (args.length === 1) {
      if (isString(args[0])) {
        key = args[0]
      } else if (isObject(args[0])) {
        if (args[0].locale) {
          locale = args[0].locale
        }
        if (args[0].key) {
          key = args[0].key
        }
      }

      options = Object.keys(args[0]).reduce((acc, key) => {
        if (includes(dateTimeFormatKeys, key)) {
          return Object.assign({}, acc, {[key]: args[0][key]})
        }
        return acc
      }, null)

    } else if (args.length === 2) {
      if (isString(args[0])) {
        key = args[0]
      }
      if (isString(args[1])) {
        locale = args[1]
      }
    }

    return this._d(value, locale, key, options)
  }

  getNumberFormat(locale) {
    return looseClone(this._vm.numberFormats[locale] || {})
  }

  setNumberFormat(locale, format) {
    this._vm.$set(this._vm.numberFormats, locale, format)
    this._clearNumberFormat(locale, format)
  }

  mergeNumberFormat(locale, format) {
    this._vm.$set(this._vm.numberFormats, locale, merge(this._vm.numberFormats[locale] || {}, format))
    this._clearNumberFormat(locale, format)
  }

  _clearNumberFormat(locale, format) {
    // eslint-disable-next-line no-autofix/prefer-const
    for (let key in format) {
      const id = `${locale}__${key}`

      if (!this._numberFormatters.hasOwnProperty(id)) {
        continue
      }

      delete this._numberFormatters[id]
    }
  }

  _getNumberFormatter(
    value,
    locale,
    fallback,
    numberFormats,
    key,
    options
  ) {
    let _locale = locale
    let formats = numberFormats[_locale]

    const chain = this._getLocaleChain(locale, fallback)
    for (let i = 0; i < chain.length; i++) {
      const current = _locale
      const step = chain[i]
      formats = numberFormats[step]
      _locale = step
      // fallback locale
      if (isNull(formats) || isNull(formats[key])) {
        if (step !== locale && process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key) && !this._isSilentFallbackWarn(key)) {
          warn(`Fall back to '${step}' number formats from '${current}' number formats.`)
        }
      } else {
        break
      }
    }

    if (isNull(formats) || isNull(formats[key])) {
      return null
    } else {
      const format = formats[key]

      let formatter
      if (options) {
        // If options specified - create one time number formatter
        formatter = new Intl.NumberFormat(_locale, Object.assign({}, format, options))
      } else {
        const id = `${_locale}__${key}`
        formatter = this._numberFormatters[id]
        if (!formatter) {
          formatter = this._numberFormatters[id] = new Intl.NumberFormat(_locale, format)
        }
      }
      return formatter
    }
  }

  _n(value, locale, key, options) {
    /* istanbul ignore if */
    if (!VueI18n.availabilities.numberFormat) {
      if (process.env.NODE_ENV !== 'production') {
        warn('Cannot format a Number value due to not supported Intl.NumberFormat.')
      }
      return ''
    }

    if (!key) {
      const nf = !options ? new Intl.NumberFormat(locale) : new Intl.NumberFormat(locale, options)
      return nf.format(value)
    }

    const formatter = this._getNumberFormatter(value, locale, this.fallbackLocale, this._getNumberFormats(), key, options)
    const ret = formatter && formatter.format(value)
    if (this._isFallbackRoot(ret)) {
      if (process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key) && !this._isSilentFallbackWarn(key)) {
        warn(`Fall back to number localization of root: key '${key}'.`)
      }
      /* istanbul ignore if */
      if (!this._root) {
        throw Error('unexpected error')
      }
      return this._root.$i18n.n(value, Object.assign({}, {key, locale}, options))
    } else {
      return ret || ''
    }
  }

  n(value, ...args) {
    let locale = this.locale
    let key = null
    let options = null

    if (args.length === 1) {
      if (isString(args[0])) {
        key = args[0]
      } else if (isObject(args[0])) {
        if (args[0].locale) {
          locale = args[0].locale
        }
        if (args[0].key) {
          key = args[0].key
        }

        // Filter out number format options only
        options = Object.keys(args[0]).reduce((acc, key) => {
          if (includes(numberFormatKeys, key)) {
            return Object.assign({}, acc, {[key]: args[0][key]})
          }
          return acc
        }, null)
      }
    } else if (args.length === 2) {
      if (isString(args[0])) {
        key = args[0]
      }
      if (isString(args[1])) {
        locale = args[1]
      }
    }

    return this._n(value, locale, key, options)
  }

  _ntp(value, locale, key, options) {
    /* istanbul ignore if */
    if (!VueI18n.availabilities.numberFormat) {
      if (process.env.NODE_ENV !== 'production') {
        warn('Cannot format to parts a Number value due to not supported Intl.NumberFormat.')
      }
      return []
    }

    if (!key) {
      const nf = !options ? new Intl.NumberFormat(locale) : new Intl.NumberFormat(locale, options)
      return nf.formatToParts(value)
    }

    const formatter = this._getNumberFormatter(value, locale, this.fallbackLocale, this._getNumberFormats(), key, options)
    const ret = formatter && formatter.formatToParts(value)
    if (this._isFallbackRoot(ret)) {
      if (process.env.NODE_ENV !== 'production' && !this._isSilentTranslationWarn(key)) {
        warn(`Fall back to format number to parts of root: key '${key}' .`)
      }
      /* istanbul ignore if */
      if (!this._root) {
        throw Error('unexpected error')
      }
      return this._root.$i18n._ntp(value, locale, key, options)
    } else {
      return ret || []
    }
  }
}

let availabilities
Object.defineProperty(VueI18n, 'availabilities', {
  get() {
    if (!availabilities) {
      const intlDefined = typeof Intl !== 'undefined'
      availabilities = {
        dateTimeFormat: intlDefined && typeof Intl.DateTimeFormat !== 'undefined',
        numberFormat: intlDefined && typeof Intl.NumberFormat !== 'undefined'
      }
    }

    return availabilities
  }
})

VueI18n.install = install
VueI18n.version = '__VERSION__'
