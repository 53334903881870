

export function last30(value) {
  if (!value) return value
  if (value.length > 30) {
    return '...' + value.slice(-30)
  }
  return value
}


export function recentTime(diff) {
  if (!diff) return diff
  diff = +diff

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else {
    return Math.ceil(diff / 3600 / 24) + '天前'
  }
}

// memorySize
export function memorySize(value) {
  if (!value) return value
  if (value < 1024) {
    return value + 'B'
  } else if (value < 1024 * 1024) {
    return (value / 1024).toFixed(2) + 'KB'
  } else if (value < 1024 * 1024 * 1024) {
    return (value / 1024 / 1024).toFixed(2) + 'MB'
  } else {
    return (value / 1024 / 1024 / 1024).toFixed(2) + 'GB'
  }
}
