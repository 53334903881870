<template>
  <div class="el-scrollbar">
    <div
      v-if="!native"
      ref="wrap"
      :style="wrapStyleObject"
      @scroll="handleScroll"
      :class="[wrapClass, 'el-scrollbar__wrap', gutter ? '' : 'el-scrollbar__wrap--hidden-default']"
    >
      <component :is="tag" class="el-scrollbar__view" :class="viewClass" :style="viewStyle" ref="resize">
        <slot></slot>
      </component>
      <Bar v-bind:move="moveX" v-bind:size="sizeWidth"/>
      <Bar vertical v-bind:move="moveY" v-bind:size="sizeHeight"/>
    </div>
    <div
      v-else
      ref="wrap"
      :style="wrapStyleObject"
      :class="[wrapClass, 'el-scrollbar__wrap']"
    >
      <component :is="tag" class="el-scrollbar__view" :class="viewClass" :style="viewStyle" ref="resize">
        <slot></slot>
      </component>
    </div>
  </div>
</template>

<script>
import Bar from './bar.vue'
import {addResizeListener, removeResizeListener} from 'element-ui/src/utils/resize-event'
import scrollbarWidth from 'element-ui/src/utils/scrollbar-width'
import {toObject} from 'element-ui/src/utils/util'

export default {
  name: 'ElScrollbar',

  components: {Bar},

  props: {
    native: Boolean,
    wrapStyle: {},
    wrapClass: {},
    viewClass: {},
    viewStyle: {},
    noresize: Boolean,
    tag: {
      type: String,
      default: 'div'
    }
  },

  data() {
    return {
      sizeWidth: '0',
      sizeHeight: '0',
      moveX: 0,
      moveY: 0
    }
  },

  computed: {
    wrap() {
      return this.$refs.wrap
    },
    wrapStyleObject() {
      let style = this.wrapStyle
      const gutter = scrollbarWidth()

      if (gutter) {
        const gutterWith = `-${gutter}px`
        const gutterStyle = `margin-bottom: ${gutterWith}; margin-right: ${gutterWith};`

        if (Array.isArray(this.wrapStyle)) {
          style = toObject(this.wrapStyle)
          style.marginRight = style.marginBottom = gutterWith
        } else if (typeof this.wrapStyle === 'string') {
          style += gutterStyle
        } else {
          style = gutterStyle
        }
      }
      return style
    }
  },

  methods: {
    handleScroll() {
      const wrap = this.wrap

      this.moveY = ((wrap.scrollTop * 100) / wrap.clientHeight)
      this.moveX = ((wrap.scrollLeft * 100) / wrap.clientWidth)
    },

    update() {
      let heightPercentage, widthPercentage
      const wrap = this.wrap
      if (!wrap) return

      heightPercentage = (wrap.clientHeight * 100 / wrap.scrollHeight)
      widthPercentage = (wrap.clientWidth * 100 / wrap.scrollWidth)

      this.sizeHeight = (heightPercentage < 100) ? (heightPercentage + '%') : ''
      this.sizeWidth = (widthPercentage < 100) ? (widthPercentage + '%') : ''
    }
  },

  mounted() {
    if (this.native) return
    this.$nextTick(this.update)
    !this.noresize && addResizeListener(this.$refs.resize, this.update)
  },

  beforeDestroy() {
    if (this.native) return
    !this.noresize && removeResizeListener(this.$refs.resize, this.update)
  }
}
</script>

<style scoped>
/* 添加你的样式 */
</style>
