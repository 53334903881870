
/**
 * 将字符串转换为Base64
 * @param {string} input 要转换的字符串
 * @returns {string} 转换后的Base64字符串
 */
export function base64(input) {
    // 使用 TextEncoder 将字符串转换为字节
    const utf8Bytes = new TextEncoder().encode(input);

    // 将字节数组转换为二进制字符串
    let binaryStr = '';
    utf8Bytes.forEach(byte => binaryStr += String.fromCharCode(byte));

    // 使用btoa()进行Base64编码
    return btoa(binaryStr)
}